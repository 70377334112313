.about_bank_today {
  margin-top: 100px;
}

.about_bank_top {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about_bank_today .left {
  width: 100%;
  color: var(--dark-blue);
}

.about_bank_today .left h3 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.about_bank_today .left p {
  font-size: 16px;
  margin-top: 48px;
}

.about_bank_today .right {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  max-height: 140px;
}

.about_bank_today .overview_points {
  margin-top: 24px;
}

.overview_points .line {
  color: var(--dark-blue);
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-blue);
}

.overview_points .line .number {
  width: 25%;
  font-size: 14px;
}

.overview_points .line .text {
  width: 75%;
  font-size: 14px;
}

.about_bank_today .btn {
  margin-top: 30px;
}

/* for md devices */
@media (min-width: 768px) {
  .about_bank_today {
    margin-top: 200px;
  }

  .about_bank_top {
    flex-direction: row;
    gap: 24px;
  }

  .about_bank_today .left {
    width: calc(50% - 12px);
  }
  .about_bank_today .right {
    width: calc(50% - 12px);
    object-fit: cover;
    max-height: 260px;
  }

  .about_bank_today .left h3 {
    font-size: 50px;
  }

  .about_bank_today .left p {
    font-size: 24px;
    margin-top: 80px;
  }

  .about_bank_today .overview_points {
    margin-top: 80px;
  }

  .overview_points .line .number {
    width: 50%;
    font-size: 18px;
  }

  .overview_points .line .text {
    width: 50%;
    font-size: 24px;
  }

  .about_bank_today .btn {
    margin-top: 60px;
  }
}

/* advantages package section start */
.advantages_package {
  margin-top: 100px;
}
.advantages_package .header_section {
  color: var(--dark-blue);
}

.header_section .left h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.header_section .left p {
  font-size: 16px;
  margin-top: 48px;
}

.header_section .right {
  display: none;
}

.advantages_flex {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  box-sizing: border-box;
  max-width: 100%;
}

.advantages_flex .left {
  object-fit: cover;
  display: block;
  border-radius: 16px;
  width: 100%;
  min-height: 360px;
}

.grid_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 100%;
}

.grid_container .card {
  padding: 24px;
  color: var(--dark-blue);
  background-color: white;
  border: 1px solid var(--light-blue);
  border-radius: 16px;
}

.card.bg_gradient {
  background: var(--gradient-background);
  color: white;
}

.grid_container .card h3 {
  font-size: 18px;
  font-weight: 400;
}

.grid_container .card p {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}

.mobile_btn {
  display: block;
  margin-top: 30px;
}

/* for md devices */
@media (min-width: 768px) {
  .advantages_package {
    margin-top: 200px;
  }

  .advantages_package .header_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header_section .left {
    width: 50%;
  }

  .header_section .left h2 {
    font-size: 50px;
  }

  .header_section .left p {
    font-size: 24px;
    margin-top: 80px;
  }

  .header_section .right {
    display: block;
  }

  .advantages_flex {
    flex-direction: row;
    margin-top: 48px;
  }

  .advantages_flex .left {
    width: calc(50% - 12px);
  }

  .mobile_btn {
    display: none;
  }
}

@media (min-width: 1024px) {
  .advantages_flex {
    gap: 24px;
  }

  .advantages_flex .right {
    width: calc(50% - 12px);
    flex: 1;
  }

  .grid_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    height: 100%;
  }
  .grid_container .card h3 {
    font-size: 24px;
    font-weight: 400;
  }

  .grid_container .card p {
    font-size: 18px;
    font-weight: 400;
  }

  .grid_container .card {
    max-width: calc(50% - 12px);
  }
}

/* advantages package section end */

.title-card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  box-sizing: border-box;
}

.quote {
  margin-top: 100px;
}

.quote p {
  background-color: white;
  color: var(--dark-blue);
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  padding: 48px 24px;
  border-radius: 16px;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.08);
}

.quote span {
  color: var(--main-blue);
}

/* Ensure responsiveness */
@media (min-width: 768px) {
  .title-card {
    max-width: 60%;
  }

  .quote {
    margin-top: 200px;
  }

  .quote p {
    font-size: 50px;
    padding: 100px 88px;
  }
}

.title-card-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-style: italic;
  font-weight: 500;
  line-height: 60.95px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.quote-container {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 2.5rem;
  margin: 20px 0 70px 0;
}

.quote-text {
  text-align: center;
  color: var(--dark-blue) !important;
  font-size: var(--h1-font-size);
  font-family: 'Mulish', sans-serif;
  font-style: italic;
  font-weight: 500;
  line-height: 60.95px;
}
.quote-light {
  color: var(--main-blue) !important;
}
.underline {
  border-bottom: 1px solid #99a8fc;
}

.responsive-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 100px;
}

.responsive-container-text-column {
  flex: 1;
}

.responsive-container-image-column {
  flex: 1;
  display: flex;
  justify-content: center;
}

.responsive-container-title {
  font-family: Montserrat;
  text-transform: uppercase;
  color: var(--dark-blue);
  font-size: 50px;
  font-weight: 700;
  line-height: 60.95px;
  text-align: left !important;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 2rem;
}

.responsive-container-paragraph {
  font-family: Montserrat;
  color: var(--dark-blue);
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.responsive-container-responsive-image {
  max-width: 684px;
  max-height: 259px;
  border-radius: 16px 0 0 0;
}

/* for md devices */
@media (min-width: 768px) {
  .responsive-container {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 1024px) {
  .responsive-container-responsive-image {
    max-width: 500px;
    max-height: 300px;
    border-radius: 16px 0 0 0;
  }
}
@media only screen and (max-width: 768px) {
  .quote-text {
    font-weight: 500;
    line-height: 30px;
    font-size: 24px;
    padding: 0.5rem;
  }
  .responsive-container {
    flex-direction: column;
  }

  .responsive-container-text-column,
  .responsive-container-image-column {
    width: 100%;
  }

  .responsive-container-responsive-image {
    width: 100%;
    height: auto;
  }
  .responsive-container-title {
    font-size: 32px;
  }
}

.responsive-benifit-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 6rem;
}

.responsive-benifit-container-text-column {
  flex: 1;
}
.benefit-text {
  color: var(--dark-blue);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--primary-line-height);
  padding-bottom: 1rem;
}

.alignRight {
  justify-content: flex-end;
}

/*--------------Benifit Block--------------------*/

.image-text-grid {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9rem;
  gap: 10px;
}

.image-column {
  flex: 1;
}

.responsive-large-image {
  width: 684px;
  height: 666px;
  border-radius: 16px 0 0 0;
}

.text-grid-column {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.text-box {
  border-radius: 16px;
  background: linear-gradient(100deg, #7484d9, #2b42aa);
  padding: 16px;
}
.text-box2 {
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  border: 1px solid #99a8fc;
}

.box-title {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 1rem;
}
.color-white {
  color: #fff;
}
.color-blue {
  color: var(--dark-blue);
}

.box-paragraph {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

@media only screen and (max-width: 1024px) {
  .responsive-large-image {
    width: 500px;
    height: auto;
    border-radius: 16px 0 0 0;
  }
  .box-paragraph {
    font-size: 16px;
    line-height: 19px;
  }
  .box-title {
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .image-text-grid {
    gap: 5px;
  }
  .text-box {
    padding: 10px;
  }
  .text-box2 {
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .image-text-grid {
    flex-direction: column;
  }

  .text-grid-column {
    grid-template-columns: 1fr;
  }

  .responsive-large-image {
    width: 100%;
    height: auto;
  }
  .box-paragraph {
    font-size: 16px;
    line-height: 19px;
  }
  .box-title {
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .text-box {
    padding: 16px;
  }
  .text-box2 {
    padding: 16px;
  }
}

/* --------------------OLD CSS-------------- */
.primary-home-content {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
}

.home-description {
  margin-top: 0.5rem;
  color: #2a2e37;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.home-img-container {
  position: relative;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-img {
  width: 100%;
  max-width: 500px;
}

.text-section {
  justify-content: center;
}

.btn-block {
  padding-top: 20px;
}

.text-block {
  display: flex;
  flex-direction: column;
}

.finance-content {
  color: #2a2e37;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: var(--secondary-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--primary-line-height);
  padding-top: 15px;
}

/* BENEFIT ------------------------------------------------- */
.benefit-container {
  padding-block: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.benefit-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  padding-top: 24px;
}

.benefit-no {
  color: #d90429;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: var(--tertiary-big-font-size);
  font-style: normal;
  font-weight: 700;
  opacity: 0.3;
}

.benefit-text {
  color: var(--dark-blue);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--primary-line-height);
}

@media screen and (min-width: 768px) {
  .benefit-row {
    flex-direction: row;
    column-gap: 3rem;
    align-items: center;
  }
}

/* HOME CARD ----------------------------  */
.home-card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding-block: 7rem 1rem;
}

.cards-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cards-info {
  color: #979797;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px;
  max-width: 840px;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
}

/* .card {
  padding: 40px 24px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(25% - 24px);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid #03045e;
  background: #fff;
} */

.card-title {
  color: #03045e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.card-content {
  color: #2a2e37;
  text-align: center;
  font-style: normal;
  line-height: 24px;
}

.cards-btn {
  margin-bottom: 3rem;
}

/* HOME CONTACT -------------------------------------------------------------- */
.home-contact-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  margin-top: 100px;
  margin-bottom: 100px;
}

.home-contact-title {
  /* text-align: center; */
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 1rem;
  color: var(--dark-blue);
}

.contact-form {
  background: white;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 1rem;
  padding-block: 4rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 70px;
}

.input-block {
  position: relative;
  width: 100%;
}

.input-block .home-contact-error {
  position: absolute;
  color: red;
  font-size: 12px;
  bottom: 5px;
}

.label {
  color: var(--dark-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* .home-contact-error {
  position: relative;
  font-size: var(--smaller-font-size);
  color: red;
  margin-bottom: 0.7rem;
} */

/* FOR LARGE DEVICES */
@media screen and (min-width: 800px) {
  .primary-home-content {
    padding-top: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .home-img-container {
    align-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .cards-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }

  .contact-form {
    padding-inline: 2rem;
  }
}

@media screen and (min-width: 1030px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .home-contact-container {
    grid-template-columns: 1.5fr 1.6fr;
    column-gap: 1rem;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .home-contact-title {
    text-align: left;
    margin: 0;
    font-size: 50px;
  }
}

@media screen and (min-width: 1030px) {
  .cards-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
